import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import {React} from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import {fetchBlogs} from "./redux/actions/blogActions"
import {fetchTags} from "./redux/actions/tagsAction";
import {fetchAilments} from "./redux/actions/ailmentsActions";
import {fetchCoupons} from "./redux/actions/couponActions.js";
import {fetchClient} from "./redux/actions/clientActions.js";
import {fetchHomePageData} from "./redux/actions/homePageActions.js";
import {fetchBusinessDetails} from './redux/actions/businessdetailsActions.js';
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';


const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer, // using persistedReducer here
  composeWithDevTools(applyMiddleware(thunk))
);




export const persistor = persistStore(store);

//fetch products from DB
  fetch('https://treatnaturally-prod.herokuapp.com/store/products/',{
    method: 'GET',
  }).then(response => {
    return response.json();
  }).then(JsonResponse =>{
    return JsonResponse.map(item =>({
      id: item.id,
      sku: item.sku,
      name: item.name,
      price: item.variations.length>0?item.variations[0].price:item.price,
      varitaions: item.variations.length>0?item.variations : "No Variations",
      discount: item.discount,
      new: item.new,
      rating: null,
      category: item.category && item.category.length>0? item.category : 
        [
          {
              "id": 29,
              "title": "No Category Found"
          }
      ],
      fullDescription: item.fullDescription,
      shortDescription: item.shortDescription,
      image:item.images.length>0?item.images:  ['../../assets/logo/Logo2.png','../../assets/logo/Logo2.png','../../assets/logo/Logo2.png'],
      stock: item.stock,
      tag:  ['item.tag'],
    }))
    }).then(response => {
      store.dispatch(fetchProducts(response));
    })

    fetch('https://treatnaturally-prod.herokuapp.com/blog',{
      method: 'GET',
    }).then((response) => {
      return response.json()
    }).then(jsonresponse =>{
      store.dispatch(fetchBlogs(jsonresponse));
    })


  fetch('https://treatnaturally-prod.herokuapp.com/tags',{
    method: 'GET',
  }).then(response => {
    return response.json();
  }).then(JsonResponse =>{
    store.dispatch(fetchTags(JsonResponse));
  })


  fetch('https://treatnaturally-prod.herokuapp.com/ailments',{
    method: 'GET',
  }).then(response => {
    return response.json();
  }).then(JsonResponse =>{
    store.dispatch(fetchAilments(JsonResponse))
  })

  {/*  fetch('https://treatnaturally-prod.herokuapp.com/store/coupons/',{
    method: 'GET',
  }).then(response => {
    return response.json();
  }).then(JsonResponse =>{
    store.dispatch(fetchCoupons(JsonResponse))
  }) */}




  fetch('https://treatnaturally-prod.herokuapp.com/graphics',{
    method: 'GET',
  }).then(response => {
    return response.json();
  }).then(JsonResponse =>{
    store.dispatch(fetchHomePageData(JsonResponse))
  })

  fetch('https://treatnaturally-prod.herokuapp.com/businessdetails/',{
    method: 'GET',
  }).then(response =>{
    return response.json();
  }).then(JsonResponse =>{
    store.dispatch(fetchBusinessDetails(JsonResponse));
  });






// Clear cache, cookies, and local storage if last visit was more than 2 hours ago
const LAST_VISIT_KEY = "lastVisit";

const clearDataIfNeeded = () => {
  const lastVisit = localStorage.getItem(LAST_VISIT_KEY);
  const now = new Date().getTime();

  if (lastVisit && now - parseInt(lastVisit) > 2 * 60 * 60 * 1000) {
    // Clear cookies
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }

    // Clear local storage
    localStorage.clear();

    // Clear cache
    caches.keys().then((cacheKeys) => {
      cacheKeys.forEach((cacheKey) => caches.delete(cacheKey));
    });

    // Claer clientdata
    store.dispatch(fetchClient([]));
  }

  // Update last visit time
  localStorage.setItem(LAST_VISIT_KEY, now.toString());
};

// Call clearDataIfNeeded when the website loads
if (!localStorage.getItem(LAST_VISIT_KEY)) {
  const now = new Date().getTime();
  localStorage.setItem(LAST_VISIT_KEY, now.toString());
}
clearDataIfNeeded();




ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


