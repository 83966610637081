import { FETCH_CARTID_SUCCESS } from "../actions/cartidAction.js";

const initState = {
    cartid: []
};

const cartidReducer = (state = initState, action) => {
    if (action.type === FETCH_CARTID_SUCCESS) {
      return {
        ...state,
        cartid: action.payload
      };
    }
  
    return state;
  };

export default cartidReducer;