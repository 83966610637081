import { FETCH_AILMENTS_SUCCESS } from "../actions/ailmentsActions.js";

const initState = {
  ailments: []
};

const ailmentsReducer = (state = initState, action) => {
  if (action.type === FETCH_AILMENTS_SUCCESS) {
    return {
      ...state,
      ailments: action.payload
    };
  }

  return state;
};

export default ailmentsReducer;