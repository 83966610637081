export const FETC_LASTORDER_SUCCESS = "FETC_LASTORDER_SUCCESS";

const fetchLastOrderSuccess = lastorder => ({
  type: FETC_LASTORDER_SUCCESS,
  payload: lastorder
});

// fetch Last Order
export const fetchLastOrder = lastorder => {
  return dispatch => {
    dispatch(fetchLastOrderSuccess(lastorder));
  };
};
