export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";

const fetchTagsSuccess = tags => ({
  type: FETCH_TAGS_SUCCESS,
  payload: tags
});

// fetch Tags
export const fetchTags = tags => {
  return dispatch => {
    dispatch(fetchTagsSuccess(tags));
  };
};
