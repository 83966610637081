export const FETCH_AILMENTS_SUCCESS = "FETCH_AILMENTS_SUCCESS";

const fetchAilmentsSuccess = ailments => ({
  type: FETCH_AILMENTS_SUCCESS,
  payload: ailments
});

// fetch Ailments
export const fetchAilments = ailments => {
  return dispatch => {
    dispatch(fetchAilmentsSuccess(ailments));
  };
};
