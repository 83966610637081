// actions/userActions.js

export const SET_USER_NAME = 'SET_USER_NAME';

export const setUserName = (data) => {
  return {
    type: SET_USER_NAME,
    payload: data,
  };
};
