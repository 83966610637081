export const FETCH_CARTID_SUCCESS = "FETCH_CARTID_SUCCESS";

const fetchCartIdSuccess = cartid => ({
  type: FETCH_CARTID_SUCCESS,
  payload: cartid
});

// fetch Tags
export const fetchCartId = cartid => {
  return dispatch => {
    dispatch(fetchCartIdSuccess(cartid));
  };
};