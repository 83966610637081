import {FETCH_HOMEPAGEDATA_SUCCESS} from '../actions/homePageActions.js';

const initState ={
    data:[]
};

const  homePageDataReducer = (state=initState,action) =>{
    if(action.type === FETCH_HOMEPAGEDATA_SUCCESS){
        return {
            ...state,
            data:action.payload,
        };
    };

    return state;
};

export default homePageDataReducer;