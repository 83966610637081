export const COUPON_ACTIVATED = "COUPON_ACTIVATED";


const activatedCopunSuccess = coupon => ({
    type: COUPON_ACTIVATED,
    payload: coupon
});

export const ActivateCoupons = coupon => {
  console.log(coupon)
    return dispatch => {
      dispatch(activatedCopunSuccess(coupon));
    };
  };
