export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";

const fetchClientSuccess = details => ({
  type: FETCH_CLIENT_SUCCESS,
  payload: details
});

// fetch clientData
export const fetchClient = details => {
    return dispatch => {
    dispatch(fetchClientSuccess(details));
  };
};


export const hello =()=>{

}