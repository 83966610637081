export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const ACTIVATE_COUPPON="ACTIVATE_COUPPON";

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductVariation,
  selectedProductPrice,
 
) => {

  return dispatch => {
    
    if (addToast) {
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    }

    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductVariation: selectedProductVariation
          ? selectedProductVariation
          : item.selectedProductVariation
          ? item.selectedProductVariation
          : null,
          selectedProductPrice: selectedProductPrice
          ? selectedProductPrice
          : item.selectedProductPrice
          ? item.selectedProductPrice
          : null,

      }
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Item Removed From Cart", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {

  return dispatch => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
    
  };
};
//delete all from cart
export const deleteAllFromCart = (addToast = null) => {
   return dispatch => {
    if (addToast) {
      console.log('Toasted')
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};
// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else{
    return item.stock;}
    {/*   else {
    return item.variation
      .filter(single => single.color === color)[0]
      .size.filter(single => single.name === size)[0].stock;
  }*/}
  
};


export const activateCouppon = (
  CouponDiscount,

) => {
  return dispatch => {


    dispatch({
      type: ACTIVATE_COUPPON,
      payload: {
        CouponDiscount:CouponDiscount
      }
    });
  };
};