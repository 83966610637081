import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import blogReducer from "./blogReducer";
import clientReducer from "./clientReducer";
import tagsReducer from "./tagsReducer";
import ailmentsReducer from "./ailmetnsReducer";
import tokenReducer from "./tokenReducer";
import cartidReducer from './cartidReducer';
import couponsReducer from "./couponReducr";
import homePageDataReducer from './homePageReducer';
import lastOrderReducer from './lastOrderReducer';
import businessDetailsReducer from './businessDetailsReducer';
import ActivatedCouponsReducer from './activateCouponReducer';
import userReducer from './TempUserReducer'
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import PrimeuserReducer from './TempPrimeUserReducer';
const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  blogData:blogReducer,
  clientData: clientReducer,
  tagData: tagsReducer,
  ailmentsData:ailmentsReducer,
  tokenData:tokenReducer,
  cartidReducer:cartidReducer,
  couponsData:couponsReducer,
  homePageData:homePageDataReducer,
  lastOrderData:lastOrderReducer,
  businessDetailsData:businessDetailsReducer,
  activatedCouponsData:ActivatedCouponsReducer,
  CheckoutReducer: userReducer,
  PrimeCheckoutReducer: PrimeuserReducer
});

export default rootReducer;
