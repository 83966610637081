import { FETC_LASTORDER_SUCCESS } from "../actions/lastOrderActions.js";

const initState = {
  lastorder: []
};

const lastOrderReducer = (state = initState, action) => {
  if (action.type === FETC_LASTORDER_SUCCESS) {
    return {
      ...state,
      lastOrder: action.payload
    };
  }

  return state;
};

export default lastOrderReducer;