export const FETCH_BUSINESSDETAILS_SUCCESS = "FETCH_BUSINESSDETAILS_SUCCESS";

const fetchBusinessDetailsSuccess = details => ({
  type: FETCH_BUSINESSDETAILS_SUCCESS,
  payload: details
});

// fetch Ailments
export const fetchBusinessDetails = details => {
  return dispatch => {
    dispatch(fetchBusinessDetailsSuccess(details));
  };
};
