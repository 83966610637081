import { FETCH_CLIENT_SUCCESS } from "../actions/clientActions.js";

const initState = {
  details: []
};

const clientReducer = (state = initState, action) => {
  if (action.type === FETCH_CLIENT_SUCCESS) {
    return {
      ...state,
      details: action.payload
    };
  }
  return state;
};

export default clientReducer;
