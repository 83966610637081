import { FETCH_BLOGS_SUCCESS } from "../actions/blogActions.js";

const initState = {
  blogs: []
};

const blogReducer = (state = initState, blog) => {
  if (blog.type === FETCH_BLOGS_SUCCESS) {
    return {
      ...state,
      blogs: blog.payload
    };
  }

  return state;

};

export default blogReducer;
