export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";

const fetchTokenSuccess = toekn => ({
  type: FETCH_TOKEN_SUCCESS,
  payload: toekn
});

// fetch Tags
export const fetchToken = token => {
  return dispatch => {
    dispatch(fetchTokenSuccess(token));
  };
};
