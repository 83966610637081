import {COUPON_ACTIVATED} from '../actions/ativatedCouponActions.js';

const initState = {
    coupons: []
};

const ActivatedCouponsReducer = (state = initState, action) => {
    if (action.type === COUPON_ACTIVATED) {
      return {
        ...state,
        Activecoupons: action.payload
      };
    }
  
    return state;
}

  export default ActivatedCouponsReducer;  