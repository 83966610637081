import {FETCH_COUPON_SUCCESS} from '../actions/couponActions.js';

const initState = {
    coupons: []
};

const couponsReducer = (state = initState, action) => {
    if (action.type === FETCH_COUPON_SUCCESS) {
      return {
        ...state,
        coupons: action.payload
      };
    }
  
    return state;
}

  export default couponsReducer;  