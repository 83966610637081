import { FETCH_TOKEN_SUCCESS } from "../actions/tokenActions.js";

const initState = {
    token: []
};

const tokenReducer = (state = initState, action) => {
    if (action.type === FETCH_TOKEN_SUCCESS) {
      return {
        ...state,
        token: action.payload
      };
    }
  
    return state;
  };

export default tokenReducer;