// actions/userActions.js

export const SET_PRIME_USER_NAME = 'SET_USER_NAME';

export const setPrimeUserName = (data) => {
  console.log(`Hi from here ${data}`)
  return {
    type: SET_PRIME_USER_NAME,
    payload: data,
  };
};
