import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;

    if (action.type === ADD_TO_CART) {
      const isVariantProduct = product.selectedProductVariation || product.selectedProductPrice;
      const cartItemIndex = cartItems.findIndex((item) =>
        item.id === product.id &&
        (!isVariantProduct || 
         (item.selectedProductVariation === product.selectedProductVariation &&
          item.selectedProductPrice === product.selectedProductPrice))
      );
    
      if (cartItemIndex !== -1) {
        const newCartItems = [...cartItems];
        const newQuantity = product.quantity ? product.quantity : 1;
        newCartItems[cartItemIndex] = {
          ...newCartItems[cartItemIndex],
          quantity: newCartItems[cartItemIndex].quantity + newQuantity
        };
        return newCartItems;
      }
    
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: product.cartItemId ? product.cartItemId : uuid()
        }
      ];
    }
    

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  return state;
};

export default cartReducer;
