import {FETCH_BUSINESSDETAILS_SUCCESS} from '../actions/businessdetailsActions.js';

const initState = {
    details: []
};

const businessDetailsReducer = (state = initState, action) => {
    if (action.type === FETCH_BUSINESSDETAILS_SUCCESS) {
      return {
        ...state,
        details: action.payload
      };
    }
  
    return state;
};

export default businessDetailsReducer;
  