export const FETCH_HOMEPAGEDATA_SUCCESS = "FETCH_HOMEPAGEDATA_SUCCESS";

const fetchHomeDataPageSuccess = data=>({
    type:FETCH_HOMEPAGEDATA_SUCCESS,
    payload:data
});


//fetch HomePageData 

export const fetchHomePageData = data => {
    return dispatch => {
      dispatch(fetchHomeDataPageSuccess(data));
    };
  };