export const FETCH_COUPON_SUCCESS = "FETCH_COUPON_SUCCESS";


const fetchCopunSuccess = coupon => ({
    type: FETCH_COUPON_SUCCESS,
    payload: coupon
});

export const fetchCoupons = coupon => {
    return dispatch => {
      dispatch(fetchCopunSuccess(coupon));
    };
  };
